import request from '@/utils/request'

/**
 * 获取店铺分组列表
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsCategoryList() {
	return request({
		url: 'seller/shops/cats',
		method: 'get',
		loading: false
	})
}

/**
 * 删除 商品分组
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function deleteGoodsCategory(params) {
	const ids = params.toString()
	return request({
		url: `seller/shops/cats/${ids}`,
		method: 'delete'
	})
}

/**
 * 商品分组 添加
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function addGoodsCategory(params) {
	return request({
		url: 'seller/shops/cats',
		method: 'post',
		data: params
	})
}

/**
 * 商品分组 编辑
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function updateGoodsCategory(ids, params) {
	return request({
		url: `seller/shops/cats/${ids}`,
		method: 'put',
		data: params
	})
}

/**
 * 发布商品 搜索商品品类 分级搜索（商城商品品类）
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsCategoryLevelList(ids, params) {
	return request({
		url: `/admin/goods/comboGoods/category/${ids}/children`,
		method: 'get',
		loading: false,
		params
	})
}


/**
 * 发布商品 搜索商品品类 分级搜索（商城商品品类）
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsCategoryLevelListAudit(ids, params) {
	return request({
		url: `admin/goods/category/${ids}/children`,
		method: 'get',
		loading: false,
		params
	})
}
//添加商品分类
export function addgoodsCategory(params) {
	return request({
		url: 'admin/goods/categories',
		method: 'post',
		data: params
	})
}